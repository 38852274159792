export const SoundCloudPlaylist = () => {
  return (
    <iframe
      title="soundcloudPlaylist"
      width="100%"
      height="100%"
      allow="autoplay"
      src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1094349469&color=%23e6a074&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
    ></iframe>
  );
};
