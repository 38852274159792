import React from "react";
// import logo from "./images/logo.jpg";
import "./App.scss";
import { SoundCloudPlaylist } from "./SoundCloud/SoundCloudPlaylist";
import { Tickets } from "./Tickets/Tickets";

function App() {
  return (
    <div className="App">
      <div className="scroll-container">
        <div className="section__top">
          <div className="logo-container">
            <a href="/">
              <div className="logo_image" />
            </a>
            <div className="menu">
              <ul>
                <li>
                  <a href="#who-are-we">WHO ARE WE?</a>
                </li>
                <li>
                  <a href="#tickets">TICKETS</a>
                </li>
                <li>
                  <a href="#schleichcasts">SCHLEICHCASTS</a>
                </li>
                <li>
                  <a href="#impressum">IMPRESSUM</a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div id="who-are-we" className="section__bottom">
          <h1>WHO ARE WE?</h1>
          <p className="bold">
            Schleichend is a music collective and creativity hub, founded by
            like-minded people. ᓚᘏᗢ
          </p>
          <div className="group_image" />

          <p className="cursive">
            Ein Gefühl, das sich allmählich, fast unbemerkt beginnend, in uns
            ausbreitet und verstärkt. Wie eine Blase, die uns aufsaugt und in
            der Tiefe der Nacht gefangen hält. Die uns erlaubt, zu träumen und
            nicht hinterfragt, wer oder was wir sind. Die sich stetig durch den
            Raum bewegt und eine Atmosphäre schafft, in der wir uns langsam,
            aber sicher im Tanzen verlieren.
          </p>
          <p className="cursive">
            A feeling that gradually, almost unnoticed, spreads and intensifies
            within us. Like a bubble that sucks us in and keeps us trapped
            within the depths of the night. That allows us to dream without
            questioning who or what we are. Moving steadily through space and
            creating an atmosphere in which we slowly but surely lose ourselves
            in dance.
          </p>
        </div>

        <div id="tickets" className="section__bottom">
          <div className="tickt url">
            <h1>TICKETS</h1>
            <div className="ticket-container">
              <Tickets />
            </div>
          </div>
        </div>
        <div id="schleichcasts" className="section__bottom">
          <h1>LISTEN TO OUR SCHLEICHCASTS</h1>

          <div className="playlist-container">
            <SoundCloudPlaylist />
          </div>
        </div>
        <div id="impressum" className="section__bottom impressum">
          <h1>IMPRESSUM</h1>
          <h2>Angaben gemäß § 5 TMG:</h2>
          <p>schleichend.de</p>
          <p>Website: Tim Tenckhoff</p>
          <h2>ADRESSE</h2>
          <p>Wilhelm-stolze Straße 34 10249 Berlin</p>

          <h2>KONTAKT </h2>
          <p>
            <a href="mailto:info@schleichend.de">info@schleichend.de</a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default App;
