export const Tickets = () => {
  return (
    <>
      <iframe
        title="tickets"
        src="https://schleichend.ticket.io/?hideConsentBanner=1"
        scrolling="no"
        frameBorder="0"
        width={"100%;"}
        height={"100%;"}
        allowTransparency={true}
      ></iframe>
      <script async src="https://schleichend.ticket.io/iframe.js"></script>
    </>
  );
};
